import React, { Suspense } from 'react';
import {
	BrowserRouter as Router
} from 'react-router-dom';
import Routes from './Routes';
import { useAuth } from './context/auth-context'
// import { useUser } from './context/user-context'
import UnauthenticatedApp from './unauthenticated-app'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css"
import 'react-toastify/dist/ReactToastify.css'

import Header from './layouts/Header'


// const browserHistory = createBrowserHistory();

function AuthenticatedApp() {

	return (
		<Router>
			<Header />
			<Suspense fallback={<div>Loading...</div>}>
				<Routes />
			</Suspense>
		</Router>
	)
}

function App() {
	const loggedIn = useAuth().loggedIn()
	return (
		<>
			{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
		</>
	);
}

export default App;
